import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout/Layout'
import BoxOffice from '../../components/Page/BoxOffice'

const preFestivalBoxOffice = ({location, data}) => {

	return (
		<Layout>
			{/*<BannerImage image={data.file.childImageSharp.fluid} />*/}
			<BoxOffice pageLocation={location} pageType={`pre`}/>
		</Layout>
	)
}

export const query = graphql`{
  file(relativePath: {eq: "general/Bozzy-16-9-006.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
    }
  }
}
`

export default preFestivalBoxOffice
